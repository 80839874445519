*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body {
  overscroll-behavior: none;
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  --slate-900: #0f172a;
  --slate-800: #1e293b;
  --slate-700: #334155;
  --slate-500: #64748b;
  --slate-300: #cbd5e1;
  --slate-400: #94a3b8;
  --slate-200: #e2e8f0;
  --slate-100: #f1f5f9;
  --slate-50: #f8fafc;
  --zinc-900: #18181b;
  --neutral-900: #171717;
  --neutral-800: #262626;
  --neutral-700: #404040;
  --neutral-600: #525252;
  --neutral-500: #737373;
  --neutral-400: #a3a3a3;
  --neutral-300: #d4d4d4;
  --emerald-100: #d1fae5;
  --yellow-200: #fef08a;
  --yellow-100: #fef9c3;
  --yellow-300: #fde047;
  --yellow-400: #facc15;
  --yellow-500: #eab308;
  --yellow-600: #ca8a04;
  --yellow-700: #a16207;
  --yellow-800: #854d0e;
  --yellow-950: #422006;
  --green-600: #16a34a;
  --green-900: #14532d;
  --blue-900: #1e3a8a;
  --sky-950: #082f49;
  color: var(--neutral-900);
  --border-color: var(--slate-400);
  --uuid-color: var(--neutral-900);
  background-color: var(--slate-300);
  font-family: Lato, sans-serif;
  line-height: 1.5;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}
/*# sourceMappingURL=index.7f57adad.css.map */
